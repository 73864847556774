import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import List from "../../../components/custom-widgets/list";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../../../components/custom-widgets/button";
import { Link } from "@reach/router";
import BusinessServicesCards from "../../../components/business-banking/BusinessServicesCards";

const CollectEarly = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(relativePath: { eq: "hero/business-banking/finsync/hero-collect-early-102523-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/business-banking/finsync/hero-collect-early-102523-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/business-banking/finsync/hero-collect-early-102523-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/business-banking/finsync/hero-collect-early-102523-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/business-banking/finsync/hero-collect-early-102523-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/business-banking/finsync/hero-collect-early-102523-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/business-banking/finsync/hero-collect-early-102523-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "finsync-hero-chevron",
    ...getHeroImgVariables(imgData),
    altText: "Happy businesswoman in an office working on a laptop.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Get Invoices Paid in 4 Hours, Not 4 Weeks",
            class: "text-white"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            text: "Get Paid Faster",
            url: "https://www.finsync.com/wafdbank/",
            class: "btn-white",
            externalLink: true,
            target: "_blank"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 3,
      active: true,
      title: "CollectEarly"
    }
  ];

  const title = "CollectEarly™ Empowers Cash Flow - FINSYNC",
    description =
      "CollectEarly™ empowers cash flow so you can get paid in hours, not weeks, in four simple steps. Get the tools to run your business smarter with WaFd Bank and FINSYNC.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-services/collect-early"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-collect-early-102523.jpg"
      }
    ]
  };

  const finsyncBenefitsList = {
    greenCircleCheckmark: true,
    accountName: "collect-early",
    items: [
      {
        id: 1,
        text: "User-friendly platform so you can save time and get back to doing what you love"
      },
      {
        id: 2,
        text: "Works with your current invoicing software"
      },
      {
        id: 3,
        text: "Create an invoice, or drag and drop an invoice into FINSYNC"
      },
      {
        id: 4,
        text: "Funds can get deposited into your synced WaFd account in hours"
      },
      {
        id: 5,
        text: "Low 2.5% fee (less than fees to accept credit cards)"
      },
      {
        id: 6,
        text: "For on-site jobs, utilize the ability to invoice and upload before and after photos on the spot to save you time matching jobs with photos"
      }
    ]
  };

  const finsyncVideoData = {
    vimeoId: "793529813",
    title: "CollectEarly™ accelerates growth exponentially for Caked Las Vegas",
    class: "m-auto iframe h-100 w-100 border-radius-12"
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1 className="text-gray-90">CollectEarly™</h1>
        <div className="row">
          <div className="col-md-6">
            <h4>
              Did you know 82% of small businesses fail because of cash flow issues? Optimize how you run your business
              with CollectEarly™ and get the tools you need to seamlessly bring your business finances in sync and get
              paid in four HOURS versus four weeks.
            </h4>
            <h4 className="text-green-60 font-weight-semi-bold">Benefits of FINSYNC:</h4>
            <List {...finsyncBenefitsList} />
          </div>
          <div className="col-md-6">
            <div>
              <VimeoVideoFrame {...finsyncVideoData} />
              <h4 className="text-green-60 font-weight-semi-bold mt-2">Hear How Our Clients Grow Faster</h4>
              <p>
                Ricky Gudino, owner of Caked Las Vegas, had a "game changing" experience with CollectEarly&trade; when
                FINSYNC and WaFd Bank partnered to help him get paid on invoices in hours instead of weeks.
              </p>
              <h5 className="text-green-60 font-weight-normal">
                &ldquo;Partnering with FINSYNC and WaFd has been a revelation for my business. It's the perfect blend of
                technology and human touch that I value immensely, making them my go-to partners for success!&rdquo;
              </h5>
              <p className="mb-0">Ali Megan Wagner, Owner of Pottinger Realty, LLC, Nogales, AZ</p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-green-60">
        <div className="container">
          <div className="d-xl-flex align-items-center">
            <h4 className="font-weight-semi-bold text-white mb-xl-0">
              As a client of WaFd Bank, join the FINSYNC network for free!
            </h4>
            <a
              className="btn-white ml-xl-4 btn w-100 w-sm-auto"
              id="finsync-btn"
              href="https://www.finsync.com/wafdbank/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Claim your savings today
            </a>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4">
              <StaticImage
                quality="100"
                placeholder="blurred"
                alt="FINSYNC payments dashboard"
                src="../../../images/thumbnail-finsync-payments.jpg"
                className="w-100"
              />
            </div>
            <div className="col-md-6">
              <h4 className="text-green-60 font-weight-semi-bold">
                CollectEarly&trade; empowers cash flow so you can get paid in hours, not weeks, in four simple steps:
              </h4>
              <ol className="green-number-sm">
                <li>Sync your WaFd business accounts to FINSYNC to see if you qualify</li>
                <li>Send an invoice to your customer using FINSYNC</li>
                <li>After your customer accepts the invoice, you have the option to CollectEarly&trade;</li>
                <li>Funds can be deposited to your synced WaFd account in hours.</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <BusinessServicesCards servicesArray={[5, 6]} />
      </section>
      <div className="container">
        <p className="text-sm text-gray-60 mt-2" id="finsync-collect-early-footnote">
          Cost is 2.5% of the transaction amount—less than most credit cards. CollectEarly&trade; is an option available
          to FINSYNC clients using the Accounting & Cash Flow Management or Complete Solution plans. Some restrictions
          apply.
        </p>
      </div>
    </SecondaryLanding>
  );
};

export default CollectEarly;
